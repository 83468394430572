import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FaRedo, FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { API_ENDPOINTS } from '../../apiConstants';
import useAuth from "../../useAuth";
import ReportsTable from './ReportsTable';
import SearchBar from '../orders/SearchBar';
import LoadingSpinner from '../orders/LoadingSpinner';
import ErrorAlert from '../orders/ErrorAlert';
import SaleReportModal from './SaleReportModal';

const ReportsPage = ({ selectedStore }) => {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'sale_time', direction: 'ascending' });
    const { getToken } = useAuth();
    const [token, setToken] = useState(null);
    const [selectedReport, setSelectedReport] = useState(null);

    useEffect(() => {
        const token = getToken();
        setToken(token);
    }, [getToken]);

    useEffect(() => {
        if (!token) return;

        const axiosInterceptor = axios.interceptors.request.use(
            (config) => {
                config.headers.Authorization = `Bearer ${token}`;
                return config;
            },
            (error) => Promise.reject(error)
        );

        return () => {
            axios.interceptors.request.eject(axiosInterceptor);
        };
    }, [token]);

    const fetchReports = useCallback(() => {
        setLoading(true);
        setError('');
        axios.get(API_ENDPOINTS.REPORTS, {
            params: {
                shop_id: selectedStore
            }
        })
            .then(response => {
                const formattedReports = response.data.map(report => ({
                    ...report,
                    sale_time: new Date(report.sale_time).toLocaleString(),
                    product_price: parseFloat(report.product_price).toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })
                }));
                setReports(formattedReports);
            })
            .catch(error => {
                console.error('Ошибка при загрузке отчётов:', error);
                setError('Не удалось загрузить данные о продажах. Попробуйте позже.');
            })
            .finally(() => setLoading(false));
    }, [selectedStore]);

    useEffect(() => {
        if (token) {
            fetchReports();
        }
    }, [token, fetchReports]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedReports = [...reports].sort((a, b) => {
            const aValue = a[key];
            const bValue = b[key];

            if (!isNaN(aValue) && !isNaN(bValue)) {
                return direction === 'ascending' ? aValue - bValue : bValue - aValue;
            }

            if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
            if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
            return 0;
        });

        setReports(sortedReports);
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />;
        }
        return <FaSort />;
    };

    const filteredReports = reports.filter(
        (report) =>
            report.products.some(product => product.productName.toLowerCase().includes(searchTerm.toLowerCase())) ||
            report.payment_type.toLowerCase().includes(searchTerm.toLowerCase()) ||
            report.receipt_number.toLowerCase().includes(searchTerm.toLowerCase())
    ).reverse();

    const handleRowClick = (report) => {
        setSelectedReport(report);
    };

    const handleCloseModal = () => {
        setSelectedReport(null);
    };

    return (
        <div className="p-6">
            <h2 className="text-2xl font-semibold mb-4">Отчёты о продажах</h2>
            <div className="flex justify-between items-center mb-4">
                <SearchBar searchTerm={searchTerm} handleSearch={handleSearch} />
                <button
                    onClick={fetchReports}
                    className="bg-gray-300 text-gray-700 p-2 rounded hover:bg-gray-400"
                >
                    <FaRedo />
                </button>
            </div>
            <ErrorAlert error={error} />
            {loading ? (
                <LoadingSpinner />
            ) : (
                <div className="flex-1 overflow-y-auto">
                    <ReportsTable
                        reports={filteredReports}
                        handleSort={handleSort}
                        getSortIcon={getSortIcon}
                        onRowClick={handleRowClick}
                    />
                </div>
            )}
            {selectedReport && (
                <SaleReportModal report={selectedReport} onClose={handleCloseModal} />
            )}
        </div>
    );
};

export default ReportsPage;