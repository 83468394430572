import React, {useState, useEffect, useCallback, useRef} from 'react';
import SearchBar from './SearchBar';
import SuggestionsList from './SuggestionsList';
import Cart from './Cart';
import TotalPrice from './TotalPrice';
import PaymentOptions from './PaymentOptions';
import {API_ENDPOINTS} from '../../apiConstants';
import useAuth from '../../useAuth';
import ConfirmationModal from './ConfirmationModal';

function SalesPage({selectedStore}) {
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [cartItems, setCartItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    //const [cashAmount, setCashAmount] = useState('');
    const [change, setChange] = useState(null);
    const {getToken} = useAuth();
    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);
    const [name, setName] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const searchTimeoutRef = useRef(null);

    useEffect(() => {
        const token = getToken();
        setToken(token);
    }, [getToken]);

    const fetchSuggestions = useCallback(async (searchTerm) => {
        if (!token) return;

        setLoading(true);
        setError(null);
        setSuccessMessage(null);

        try {
            const response = await fetch(`${API_ENDPOINTS.PRODUCTS}?search=${searchTerm}&shop_id=${selectedStore}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch suggestions');
            }

            const data = await response.json();
            setSuggestions(Array.isArray(data) ? data : []);
        } catch (error) {
            console.error('Error fetching product suggestions:', error);
            setError(`Ошибка при получении предложений: ${error.message}`);
        } finally {
            setLoading(false);
        }
    }, [token, selectedStore]);

    useEffect(() => {
        if (searchTerm.trim() === '') {
            setSuggestions([]);
            return;
        }

        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }

        searchTimeoutRef.current = setTimeout(() => {
            fetchSuggestions(searchTerm);
        }, 500);

        return () => clearTimeout(searchTimeoutRef.current);
    }, [searchTerm, fetchSuggestions]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const fetchName = useCallback(async () => {
        if (!token) return;

        try {
            const response = await fetch(`${API_ENDPOINTS.SALARY_SELF}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            const data = await response.json();
            setName(data.name);
        } catch (error) {
            console.error('Error fetching salary:', error);
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            fetchName();
        }
    }, [token, fetchName]);


    const addProductsToCart = (products) => {
        if (!Array.isArray(products)) return;

        products.forEach(product => {
            const existingItem = cartItems.find(item => item.id === product.id);
            const price = parseFloat(product.retailPrice);

            if (isNaN(price)) {
                console.error('Invalid price:', product.retailPrice);
                setError('Ошибка в цене товара');
                return;
            }

            if (existingItem) {
                setCartItems(cartItems.map(item =>
                    item.id === product.id ? {...item, quantity: item.quantity + 1} : item
                ));
            } else {
                setCartItems([...cartItems, {...product, quantity: 1}]);
            }
            setTotal(prevTotal => parseFloat((prevTotal + price).toFixed(2)));
        });
    };

    const handleRemoveItem = (item) => {
        const price = parseFloat(item.retailPrice);
        const updatedCartItems = cartItems.filter(cartItem => cartItem.id !== item.id);
        setCartItems(updatedCartItems);
        setTotal(prevTotal => parseFloat((prevTotal - price * item.quantity).toFixed(2)));
    };

    const handleIncreaseItem = (item) => {
        const price = parseFloat(item.retailPrice);
        setCartItems(cartItems.map(cartItem =>
            cartItem.id === item.id ? {...cartItem, quantity: cartItem.quantity + 1} : cartItem
        ));
        setTotal(prevTotal => parseFloat((prevTotal + price).toFixed(2)));
    };

    const handleDecreaseItem = (item) => {
        if (item.quantity === 1) {
            handleRemoveItem(item);
        } else {
            const price = parseFloat(item.retailPrice);
            setCartItems(cartItems.map(cartItem =>
                cartItem.id === item.id ? {...cartItem, quantity: cartItem.quantity - 1} : cartItem
            ));
            setTotal(prevTotal => parseFloat((prevTotal - price).toFixed(2)));
        }
    };

    const handleQuantityChange = (item, quantity) => {
        setError('');
        const price = parseFloat(item.retailPrice);
        const newQuantity = parseInt(quantity);
        if (newQuantity <= 0) {
            handleRemoveItem(item);
        } else {
            if (isNaN(newQuantity) || newQuantity < 1) {
                setError('Введите корректное количество товара');
                return;
            }

            setCartItems(cartItems.map(cartItem =>
                cartItem.id === item.id ? {...cartItem, quantity: newQuantity} : cartItem
            ));

            //dynamically count total price
            const newTotal = cartItems.reduce((acc, cartItem) => {
                if (cartItem.id === item.id) {
                    return acc + price * newQuantity;
                }
                return acc + cartItem.retailPrice * cartItem.quantity;
            }, 0);
            setTotal(newTotal);
        }
    }

    const handlePaymentForce = async () => (
        await handlePayment(1, 1)
    )

    const handlePayment = async (a = null, force = null) => {
        if (!paymentMethod) {
            setError('Выберите тип оплаты');
            return;
        }

        if (cartItems.length === 0) {
            setError('Корзина пуста');
            return;
        }

        // if (paymentMethod === 'cash') {
        //     const cashEntered = parseFloat(cashAmount);
        //     if (isNaN(cashEntered) || cashEntered < total) {
        //         setError('Недостаточно наличных');
        //         return;
        //     }
        //     setChange(`Сдача: ${(cashEntered - total).toFixed(2)} ₽`);
        // }

        let paymentMethodId = -1;

        switch (paymentMethod) {
            case 'cash':
                paymentMethodId = 1;
                break;
            case 'card':
                paymentMethodId = 2;
                break;
            case 'yandex':
                paymentMethodId = 3;
                break;
            case 'ozon':
                paymentMethodId = 4;
                break;
            default:
                paymentMethodId = -1;
        }

        setError(null);
        setSuccessMessage(null);
        console.log(force);
        const payload = {
            payType: paymentMethodId,
            force: force != null,
            shop_id: selectedStore,
            items: cartItems.map(item => ({
                productId: item.id,
                quantity: item.quantity
            })),
        };

        try {
            const response = await fetch(`${API_ENDPOINTS.SELL}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to process sale');
            }

            const data = await response.json();
            setSuccessMessage(`Продажа успешно обработана. Итоговая сумма: ${data['totalPrice']}`);

            setCartItems([]);
            setTotal(0);
            setPaymentMethod('');
            //setCashAmount('');
            setChange(null);
        } catch (error) {
            console.error('Ошибка при обработке продажи:', error);
            setError(error.message);
            setIsModalOpen(true);
        }
    };

    const changeColorClass = () => {
        if (change === null) return '';
        return change.startsWith('Введите корректную сумму') ? 'text-red-600' : 'text-green-600';
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleModalConfirm = async () => {
        setIsModalOpen(false);
        await handlePaymentForce();
    };

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter' && !isModalOpen) {
            e.preventDefault();
            console.log('Enter key pressed');
            const barcode = searchTerm.trim();
            if (barcode) {
                try {
                    const response = await fetch(`${API_ENDPOINTS.PRODUCTS}?search=${barcode}&shop_id=${selectedStore}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        }
                    });

                    if (!response.ok) {
                        const errorData = await response.json();
                        throw new Error(errorData.error || 'Failed to fetch product by barcode');
                    }

                    const data = await response.json();
                    addProductsToCart(data);
                    setSearchTerm('');
                } catch (error) {
                    console.error('Error fetching product by barcode:', error);
                    setError(`Ошибка при получении товара по штрихкоду: ${error.message}`);
                }
            }
        }
    };

    return (
        <div className="flex h-screen p-4">
            <div className="flex flex-col w-1/2 p-4 items-center relative">
                <div className="relative w-full absolute">
                    <SearchBar
                        value={searchTerm}
                        onChange={handleSearchChange}
                        onKeyDown={handleKeyPress}
                    />
                    <SuggestionsList
                        suggestions={suggestions}
                        onSelect={addProductsToCart}
                        searchTerm={searchTerm}
                        loading={loading}
                    />
                </div>
                <div className="absolute bottom-0 left-0 p-2 text-gray-600">
                    {name}
                </div>
            </div>
            <div className="flex flex-col w-1/2 p-4 bg-gray-100 rounded-lg shadow-lg ml-4">
            <Cart items={cartItems} onRemove={handleRemoveItem} onIncrease={handleIncreaseItem}
                      onDecrease={handleDecreaseItem} onQuantityChange={handleQuantityChange}/>
                <TotalPrice total={total}/>
                {change && <div className={`mt-2 ${changeColorClass()}`}>{change}</div>}
                <PaymentOptions
                    onPayment={handlePayment}
                    onMethodSelect={(method) => {
                        setPaymentMethod(method);
                        setChange(null);
                    }}
                    selectedMethod={paymentMethod}
                />
                {error && <div className="text-red-500 mt-4">{error}</div>}
                {successMessage && <div className="text-green-500 mt-4">{successMessage}</div>}
            </div>
            <ConfirmationModal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                onConfirm={() => handleModalConfirm()}
                errorMessage={error}
            />
        </div>
    );
}

export default SalesPage;
