import React, { useState, useEffect, useCallback } from 'react';
import { API_ENDPOINTS } from '../../apiConstants';
import useAuth from "../../useAuth";

const DashboardPage = () => {
    const [salary, setSalary] = useState('¯\\_(ツ)_/¯');
    const { getToken } = useAuth();
    const [token, setToken] = useState(null);

    useEffect(() => {
        const token = getToken();
        setToken(token);
    }, [getToken]);

    const fetchSalary = useCallback(async () => {
        if (!token) return;

        try {
            const response = await fetch(`${API_ENDPOINTS.SALARY_SELF}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            const data = await response.json();
            setSalary(data.final_salary);
        } catch (error) {
            console.error('Error fetching salary:', error);
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            fetchSalary();
        }
    }, [token, fetchSalary]);

    return (
        <div className="max-w-md mx-auto bg-white rounded-lg shadow-lg p-6 my-10">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Это мне на BMW, вы молодцы, ваша зп мне очень помогает</h2>
            <h3 className="text-xl text-gray-700">Заработано: <span className="font-semibold">{salary} ₽</span></h3>
        </div>
    );
}
export default DashboardPage;