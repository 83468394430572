import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ClientsPage from './ClientsPage';
import ReportsPage from './reports/ReportsPage';
import ProductsPage from './products/ProductsPage';
import SalesPage from "./sales/SalesPage";
import InventoryPage from "./inventory/InventoryPage";
import OrdersPage from "./orders/OrdersPage";
import InitialInventoryPage from "./initinventory/InitialInventoryPage";
import ReceivingPage from "./receiving/ReceivingPage";
import SalaryPage from "./salary/SalaryPage";
import TillComponent from "./till/TillComponent";
import DetailsTable from "./details/DetailsTable";
import DashboardPage from "./dashboard/DashboardPage";

const Content = () => {
    return (
        <div className="flex-grow p-6 overflow-auto">
            <Routes>
                <Route path="/" element={<DashboardPage />} />
                <Route path="/clients" element={<ClientsPage />} />
                <Route path="/reports" element={<ReportsPage />} />
                <Route path="/products" element={<ProductsPage />} />
                <Route path="/sales" element={<SalesPage />} />
                <Route path="/orders" element={<OrdersPage />} />
                <Route path="/inventory" element={<InventoryPage />} />
                <Route path="/inventory/init" element={<InitialInventoryPage />} />
                <Route path="/receiving" element={<ReceivingPage />} />
                <Route path="/salary" element={<SalaryPage />} />
                <Route path="/till" element={<TillComponent />} />
                <Route path="/details" element={<DetailsTable />} />
            </Routes>
        </div>
    );
};

export default Content;
