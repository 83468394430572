import React, { useState, useEffect, useCallback } from 'react';
import { API_ENDPOINTS } from '../../apiConstants';
import useAuth from '../../useAuth';

function TillComponent({ selectedStore }) {
    const [balance, setBalance] = useState('¯\\_(ツ)_/¯');
    const [amount, setAmount] = useState('');
    const [purpose, setPurpose] = useState('');
    const [history, setHistory] = useState([]);
    const { getToken } = useAuth();
    const [token, setToken] = useState(null);

    useEffect(() => {
        const token = getToken();
        setToken(token);
    }, [getToken]);

    const fetchBalance = useCallback(async () => {
        if (!token) return;

        try {
            const response = await fetch(`${API_ENDPOINTS.BALANCE}?shop_id=${selectedStore}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            const data = await response.json();
            setBalance(data.balance);
        } catch (error) {
            console.error('Error fetching balance:', error);
        }
    }, [selectedStore, token]);

    const fetchHistory = useCallback(async () => {
        if (!token) return;

        try {
            const response = await fetch(`${API_ENDPOINTS.HISTORY}?shop_id=${selectedStore}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            const data = await response.json();
            setHistory(data);
        } catch (error) {
            console.error('Error fetching history:', error);
        }
    }, [selectedStore, token]);

    useEffect(() => {
        if (token) {
            fetchBalance();
            fetchHistory();
        }
    }, [token, fetchBalance, fetchHistory]);

    const handlePutMoney = async (e) => {
        e.preventDefault();
        if (!token) return;

        try {
            await fetch(API_ENDPOINTS.PUT_MONEY, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_id: selectedStore,
                    amount: parseFloat(amount),
                }),
            });
            setAmount('');
            fetchBalance();
            fetchHistory();
        } catch (error) {
            console.error('Error putting money:', error);
        }
    };

    const handleTakeMoney = async (e) => {
        e.preventDefault();
        if (!token) return;

        try {
            await fetch(API_ENDPOINTS.TAKE_MONEY, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    shop_id: selectedStore,
                    amount: parseFloat(amount),
                    purpose,
                }),
            });
            setAmount('');
            setPurpose('');
            fetchBalance();
            fetchHistory();
        } catch (error) {
            console.error('Error taking money:', error);
        }
    };

    return (
        <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg p-6 my-10">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Касса магазина</h2>
            <h3 className="text-xl text-gray-700 mb-6">Текущий баланс: <span className="font-semibold">{balance} ₽</span></h3>

            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <form onSubmit={handlePutMoney} className="bg-gray-50 p-4 rounded-lg shadow">
                    <h4 className="text-lg font-semibold text-gray-700 mb-2">Пополнить кассу</h4>
                    <input
                        type="number"
                        placeholder="Сумма"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        required
                        className="w-full p-2 mb-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                    />
                    <button
                        type="submit"
                        className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
                    >
                        Положить деньги
                    </button>
                </form>

                <form onSubmit={handleTakeMoney} className="bg-gray-50 p-4 rounded-lg shadow">
                    <h4 className="text-lg font-semibold text-gray-700 mb-2">Снять деньги</h4>
                    <input
                        type="number"
                        placeholder="Сумма"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        required
                        className="w-full p-2 mb-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                    />
                    <input
                        type="text"
                        placeholder="Назначение"
                        value={purpose}
                        onChange={(e) => setPurpose(e.target.value)}
                        required
                        className="w-full p-2 mb-3 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                    />
                    <button
                        type="submit"
                        className="w-full bg-red-500 text-white py-2 rounded hover:bg-red-600"
                    >
                        Снять деньги
                    </button>
                </form>
            </div>

            <h4 className="text-lg font-semibold text-gray-700 mt-6 mb-2">История операций</h4>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white">
                    <thead className="bg-gray-200">
                    <tr>
                        <th className="py-2 px-4 text-left text-gray-600">Сумма</th>
                        <th className="py-2 px-4 text-left text-gray-600">Пользователь</th>
                        <th className="py-2 px-4 text-left text-gray-600">Назначение</th>
                        <th className="py-2 px-4 text-left text-gray-600">Дата</th>
                    </tr>
                    </thead>
                    <tbody>
                    {history.map((item) => (
                        <tr key={item.id} className="border-t">
                            <td className="py-2 px-4">{item.amount} ₽</td>
                            <td className="py-2 px-4">{item.user_name}</td>
                            <td className="py-2 px-4">{item.purpose}</td>
                            <td className="py-2 px-4">{new Date(item.created_at).toLocaleString()}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TillComponent;
