import React from 'react';

const ReportsTable = ({ reports, handleSort, getSortIcon, onRowClick }) => {
    return (
        <div className="overflow-x-auto bg-white shadow-lg rounded-lg h-full flex flex-col">
            <div className="overflow-y-auto flex-grow">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50 sticky top-0 z-10">
                    <tr>
                        <th className="py-3 px-6 text-left text-sm font-medium text-gray-500 cursor-pointer" onClick={() => handleSort('products')}>
                            <span className="flex items-center">
                                Товары {getSortIcon('products')}
                            </span>
                        </th>
                        <th className="py-3 px-6 text-left text-sm font-medium text-gray-500 cursor-pointer" onClick={() => handleSort('product_price')}>
                            <span className="flex items-center">
                                Итого {getSortIcon('product_price')}
                            </span>
                        </th>
                        <th className="py-3 px-6 text-left text-sm font-medium text-gray-500 cursor-pointer" onClick={() => handleSort('payment_type')}>
                            <span className="flex items-center">
                                Тип оплаты {getSortIcon('payment_type')}
                            </span>
                        </th>
                        <th className="py-3 px-6 text-left text-sm font-medium text-gray-500 cursor-pointer" onClick={() => handleSort('employee_name')}>
                            <span className="flex items-center">
                                Сотрудник {getSortIcon('employee_name')}
                            </span>
                        </th>
                        <th className="py-3 px-6 text-left text-sm font-medium text-gray-500 cursor-pointer" onClick={() => handleSort('sale_time')}>
                            <span className="flex items-center">
                                Дата продажи {getSortIcon('sale_time')}
                            </span>
                        </th>
                        <th className="py-3 px-6 text-left text-sm font-medium text-gray-500 cursor-pointer" onClick={() => handleSort('receipt_number')}>
                            <span className="flex items-center">
                                Номер чека {getSortIcon('receipt_number')}
                            </span>
                        </th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {reports.map((report) => (
                        <tr key={report.id} className="hover:bg-gray-100 cursor-pointer" onClick={() => onRowClick(report)}>
                            <td className="py-4 px-6 text-sm text-gray-500">
                                {report.products.map(product => (
                                    <div key={product.productId}>
                                        {product.productName} x{product.quantity} - {product.totalPrice}₽
                                    </div>
                                ))}
                            </td>
                            <td className="py-4 px-6 text-sm text-gray-500">{report.product_price}</td>
                            <td className="py-4 px-6 text-sm text-gray-500">{report.payment_type}</td>
                            <td className="py-4 px-6 text-sm text-gray-500">{report.employee_name}</td>
                            <td className="py-4 px-6 text-sm text-gray-500">{report.sale_time}</td>
                            <td className="py-4 px-6 text-sm text-gray-500">{report.receipt_number}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ReportsTable;