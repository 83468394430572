import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddProductRow from '../inventory/AddProductRow';
import InventoryTable from './InventoryTable';
import { CheckIcon, XCircleIcon, PaperAirplaneIcon } from '@heroicons/react/solid';
import { API_ENDPOINTS } from '../../apiConstants';
import useAuth from '../../useAuth';

const InitialInventoryPage = ({ selectedStore }) => {
    const [products, setProducts] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const { getToken } = useAuth();
    const [token, setToken] = useState(null);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const token = getToken();
        setToken(token);
    }, [getToken]);

    useEffect(() => {
        if (!token) return;

        const fetchProducts = async () => {
            try {
                const response = await axios.get(API_ENDPOINTS.PRODUCTS, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    params: {
                        shop_id: selectedStore
                    }
                });
                setProducts(response.data);
            } catch (error) {
                console.error('Error fetching products:', error);
                alert('Ошибка при загрузке товаров.');
            }
        };

        fetchProducts();
    }, [token, selectedStore]);

    const handleAddProduct = (product) => {
        const existingProduct = products.find(p => p.barcode === product.barcode);
        if (existingProduct) {
            alert('Товар с таким штрих-кодом уже добавлен!');
            return;
        }
        setProducts([...products, product]);
    };

    const handleUpdateProduct = (index, updatedProduct) => {
        const updatedProducts = products.map((product, i) =>
            i === index ? updatedProduct : product
        );
        setProducts(updatedProducts);
    };

    const handleSendResults = () => {
        setShowConfirmationModal(true);
    };

    const closeModal = () => {
        setShowConfirmationModal(false);
    };

    const closeErrorModal = () => {
        setShowErrorModal(false);
    };

    const confirmSendResults = async () => {
        setShowConfirmationModal(false);

        try {
            const response = await axios.post(API_ENDPOINTS.FIRST_INVENTORY, { results: products }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status !== 200) {
                throw new Error('Failed to send results');
            }

            alert('Данные о товарах успешно отправлены!');
        } catch (error) {
            console.error('Error during result submission:', error);
            if (error.response && error.response.data && error.response.data.errors) {
                setErrors(error.response.data.errors);
                setShowErrorModal(true);
            } else {
                alert('Ошибка при отправке товаров.');
            }
        }
    };

    return (
        <div className="min-h-screen m-4 flex flex-col">
            <h1 className="text-2xl font-semibold mb-4">Первичная инвентаризация</h1>
            <div className="flex justify-between items-center mb-6">
                <AddProductRow onAddProduct={handleAddProduct} />
                <button
                    onClick={handleSendResults}
                    className="flex items-center bg-green-600 text-white py-2 px-4 rounded-md shadow hover:bg-green-700 transition duration-300"
                >
                    <PaperAirplaneIcon className="h-5 w-5 mr-2" /> Отправить результаты
                </button>
            </div>
            <div className="flex-1 overflow-auto">
                <InventoryTable
                    products={products}
                    onUpdateProduct={handleUpdateProduct}
                    errors={errors}
                />
            </div>
            {showConfirmationModal && (
                <>
                    <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded shadow-lg relative">
                            <h2 className="text-xl font-semibold mb-4">Вы уверены, что хотите отправить результаты?</h2>
                            <div className="flex justify-end space-x-4">
                                <button
                                    onClick={closeModal}
                                    className="flex items-center bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 transition duration-300"
                                >
                                    <XCircleIcon className="h-5 w-5 mr-2" /> Отмена
                                </button>
                                <button
                                    onClick={confirmSendResults}
                                    className="flex items-center bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 transition duration-300"
                                >
                                    <CheckIcon className="h-5 w-5 mr-2" /> Подтвердить
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {showErrorModal && (
                <>
                    <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded shadow-lg relative">
                            <h2 className="text-xl font-semibold mb-4">Ошибки при отправке результатов</h2>
                            <div className="mb-4">
                                <h3 className="text-red-600 font-semibold">Ошибки:</h3>
                                <ul className="list-disc list-inside text-red-600">
                                    {Object.entries(errors).map(([key, value]) => (
                                        <li key={key}>{`${key}: ${value.join(', ')}`}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className="flex justify-end space-x-4">
                                <button
                                    onClick={closeErrorModal}
                                    className="flex items-center bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 transition duration-300"
                                >
                                    <XCircleIcon className="h-5 w-5 mr-2" /> Закрыть
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default InitialInventoryPage;