import React, { useState } from 'react';

function InventoryRow({ product, index, onUpdateProduct, error }) {
    const [isEditing, setIsEditing] = useState(false);
    const [editedProduct, setEditedProduct] = useState(product);

    const handleEditChange = (e) => {
        setEditedProduct({
            ...editedProduct,
            [e.target.name]: e.target.value,
        });
    };

    const handleSave = () => {
        onUpdateProduct(index, editedProduct);
        setIsEditing(false);
    };

    return (
        <tr className={`hover:bg-gray-100 ${product.issue ? 'bg-red-100' : ''}`}>
            <td className="py-4 px-6 text-sm text-gray-800 max-w-24 truncate" title={product.productName} style={{ textAlign: 'left' }}>
                {isEditing ? (
                    <input
                        type="text"
                        name="productName"
                        value={editedProduct.productName}
                        onChange={handleEditChange}
                        className="border border-gray-300 p-1 rounded-md"
                    />
                ) : (
                    product.productName
                )}
            </td>
            <td className="py-4 px-6 text-sm text-gray-800 max-w-xs truncate" title={product.barcode} style={{ textAlign: 'left' }}>
                {isEditing ? (
                    <input
                        type="text"
                        name="barcode"
                        value={editedProduct.barcode}
                        onChange={handleEditChange}
                        className="border border-gray-300 p-1 rounded-md"
                    />
                ) : (
                    product.barcode
                )}
            </td>
            <td className="py-4 px-6 text-sm text-gray-800 max-w-xs truncate" title={product.amountInStock} style={{ textAlign: 'left' }}>
                {isEditing ? (
                    <>
                        <input
                            type="number"
                            name="amountInStock"
                            value={editedProduct.amountInStock}
                            onChange={handleEditChange}
                            className="border border-gray-300 p-1 rounded-md"
                        />
                        {error && <div className="text-red-500 text-xs mt-1">{error[0]}</div>}
                    </>
                ) : (
                    product.amountInStock
                )}
            </td>
            <td className="py-4 px-6 text-sm text-gray-800 max-w-xs truncate" style={{ textAlign: 'left' }}>
                {isEditing ? (
                    <button
                        onClick={handleSave}
                        className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                    >
                        Сохранить
                    </button>
                ) : (
                    <button
                        onClick={() => setIsEditing(true)}
                        className="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-gray-700"
                    >
                        Изменить
                    </button>
                )}
            </td>
        </tr>
    );
}

export default InventoryRow;