import React, { useState, useEffect, useCallback, useRef } from 'react';
import MyGrid from './MyGrid';
import { API_ENDPOINTS } from '../../apiConstants';
import useAuth from "../../useAuth";

const DetailsTable = ({ selectedStore }) => {
    const { getToken } = useAuth();
    const [token, setToken] = useState(null);
    const [details, setDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const gridApiRef = useRef();

    const columnDefs = [
        { headerName: 'Уникальный номер', field: 'id' },
        { headerName: 'Деталь', field: 'name' },
    ];

    useEffect(() => {
        const token = getToken();
        setToken(token);
    }, [getToken]);

    const fetchDetails = useCallback(async () => {
        if (!token) return;

        setLoading(true);
        setError(null);

        try {
            const response = await fetch(`${API_ENDPOINTS.DETAILS}?shop_id=${selectedStore}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch details');
            }

            const data = await response.json();
            setDetails(Array.isArray(data) ? data : []);
        } catch (error) {
            setError(`Ошибка при получении данных: ${error.message}`);
        } finally {
            setLoading(false);
        }
    }, [token, selectedStore]);

    useEffect(() => {
        fetchDetails();
    }, [fetchDetails]);

    return (
        <div className="p-6">
            <h2 className="text-2xl font-bold mb-4">Список деталей</h2>
            {loading && <p className="text-gray-500">Загрузка...</p>}
            {error && <p className="text-red-500">{error}</p>}
            <MyGrid rowData={details} columnDefs={columnDefs} gridApiRef={gridApiRef} />
        </div>
    );
};

export default DetailsTable;
