import React, {useState, useEffect} from 'react';
import {API_ENDPOINTS} from '../../apiConstants';
import useAuth from "../../useAuth";

const SalaryPage = () => {
    const [employees, setEmployees] = useState([]);
    const [inputValues, setInputValues] = useState({});
    const [history, setHistory] = useState([]);
    const {getToken} = useAuth();
    const [token, setToken] = useState(null);

    useEffect(() => {
        const token = getToken();
        setToken(token);
    }, [getToken]);

    useEffect(() => {
        if(!token) return;
        const fetchEmployees = async () => {
            try {
                const response = await fetch(API_ENDPOINTS.EMPLOYEES, {
                    headers: {'Authorization': `Bearer ${token}`},
                });
                if (!response.ok) {
                    throw new Error('Ошибка при получении списка сотрудников');
                }
                const employeesData = await response.json();

                const employeesWithSalary = await Promise.all(
                    employeesData.map(async (employee) => {
                        const salaryResponse = await fetch(API_ENDPOINTS.SALARY.replace('{employeeId}', employee.id), {
                            headers: {'Authorization': `Bearer ${token}`},
                        });
                        if (!salaryResponse.ok) {
                            throw new Error(`Ошибка при получении зарплаты для сотрудника ${employee.name}`);
                        }
                        const salaryData = await salaryResponse.json();
                        return {
                            ...employee,
                            amountDue: salaryData.final_salary
                        };
                    })
                );

                setEmployees(employeesWithSalary);
            } catch (error) {
                console.error('Error fetching employees or salary data:', error);
                alert(error.message);
            }
        }
        const fetchPaymentHistory = async () => {
            try {
                const response = await fetch(API_ENDPOINTS.SALARY_HISTORY, {
                    headers: {'Authorization': `Bearer ${token}`},
                });
                if (!response.ok) {
                    throw new Error('Ошибка при получении истории выплат');
                }
                const historyData = await response.json();
                setHistory(historyData.data || []);
            } catch (error) {
                console.error('Error fetching payment history:', error);
                alert(error.message);
            }
        }

        fetchEmployees();
        fetchPaymentHistory();

    }, [token]);

    const handleInputChange = (id, value) => {
        setInputValues({...inputValues, [id]: value});
    };

    const handlePaySalary = async (id) => {
        const amount = parseFloat(inputValues[id]) || 0;

        if (amount > 0) {
            try {
                const response = await fetch(`${API_ENDPOINTS.PAY_SALARY}/${id}`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({amount}),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Ошибка при выплате зарплаты');
                }

                await response.json();

                setHistory(prevHistory => [
                    ...prevHistory,
                    {
                        user: {name: employees.find(emp => emp.id === id).name},
                        amount,
                        created_at: new Date().toISOString()
                    }
                ]);

                setEmployees(prevEmployees =>
                    prevEmployees.map(emp => emp.id === id ? {...emp, amountDue: emp.amountDue - amount} : emp)
                );

                setInputValues({...inputValues, [id]: ''});

                alert('Зарплата успешно выплачена!');
            } catch (error) {
                console.error('Error paying salary:', error);
                alert(error.message);
            }
        } else {
            alert('Некорректная сумма выплаты!');
        }
    };

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString();
    };

    const formatAmount = (amount) => {
        const numAmount = parseFloat(amount);
        return isNaN(numAmount) ? '0.00' : numAmount.toFixed(2);
    };

    return (
        <div className="max-w-4xl mx-auto p-5">
            <h1 className="text-2xl font-bold mb-4">Выдача зарплаты</h1>
            <ul className="space-y-4">
                {employees.map(employee => (
                    <li
                        key={employee.id}
                        className={`flex items-center justify-between border p-4 rounded shadow ${employee.amountDue < 0 ? 'bg-red-200' : 'bg-white'}`}
                    >
                        <span>{employee.name}: {formatAmount(employee.amountDue || 0)} руб.</span>
                        <div className="flex items-center space-x-2">
                            <input
                                type="number"
                                value={inputValues[employee.id] || ''}
                                onChange={(e) => handleInputChange(employee.id, e.target.value)}
                                placeholder="Сумма к выплате"
                                className="border rounded p-2"
                            />
                            <button
                                onClick={() => handlePaySalary(employee.id)}
                                className="bg-green-500 text-white rounded px-4 py-2 hover:bg-green-600"
                            >
                                Выплатить
                            </button>
                        </div>
                    </li>
                ))}
            </ul>

            <h2 className="text-xl font-semibold mt-8">История выплат</h2>
            <ul className="mt-4 space-y-2">
                {history.map((record, index) => (
                    <li key={index} className="border-b py-2">
                        {formatDateTime(record.created_at)} | {record.user.name} -
                        выплачено {formatAmount(record.amount)} руб.
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SalaryPage;
