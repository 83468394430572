import React from 'react';
import InventoryRow from './InventoryRow';

function InventoryTable({ products, onUpdateProduct, errors }) {
    return (
        <div className="relative bg-white shadow-lg rounded-lg">
            <div className="sticky top-0 z-10 bg-gray-100">
                <table className="min-w-full divide-y divide-gray-300 bg-gray-50">
                    <thead>
                    <tr>
                        <th className="py-3 px-6 text-left text-sm font-semibold text-gray-700">Название товара</th>
                        <th className="py-3 px-6 text-left text-sm font-semibold text-gray-700">Артикул товара</th>
                        <th className="py-3 px-6 text-left text-sm font-semibold text-gray-700">Количество</th>
                        <th className="py-3 px-6 text-left text-sm font-semibold text-gray-700">Действия</th>
                    </tr>
                    </thead>
                </table>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-300 bg-gray-50">
                    <tbody>
                    {products.map((product, index) => (
                        <InventoryRow
                            key={index}
                            product={product}
                            index={index}
                            onUpdateProduct={onUpdateProduct}
                            error={errors[`results.${index}.amountInStock`]}
                        />
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default InventoryTable;