import React from 'react';
import { FiSearch } from 'react-icons/fi';

const SearchBar = ({ searchTerm, handleSearch }) => {
    return (
        <div className="relative">
            <input
                type="text"
                placeholder="Поиск по заказу или контрагенту"
                value={searchTerm}
                onChange={handleSearch}
                className="border border-gray-300 p-2 pl-10 rounded w-80"
            />
            <FiSearch className="absolute left-3 top-2.5 text-gray-400" size={20} />
        </div>
    );
};

export default SearchBar;