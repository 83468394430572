//const BASE_URL = 'http://localhost:8000/api';
const BASE_URL = 'https://api.gaprix.ru/api';

export const API_ENDPOINTS = {
    PRODUCTS: `${BASE_URL}/products`,
    ADD_PRODUCT: `${BASE_URL}/products/create`,
    INVENTORY: `${BASE_URL}/inventory`,
    LOGIN: `${BASE_URL}/login`,
    SELL: `${BASE_URL}/sell`,
    PRODUCTS_INVENTORY: `${BASE_URL}/inventory/check`,
    DASHBOARD: `${BASE_URL}/dashboard`,
    ORDERS: `${BASE_URL}/orders`,
    REPORTS: `${BASE_URL}/reports`,
    FIRST_INVENTORY: `${BASE_URL}/inventory/first`,
    PAY_SALARY: `${BASE_URL}/pay-salary`,
    EMPLOYEES: `${BASE_URL}/employees`,
    SALARY: `${BASE_URL}/salary/{employeeId}`,
    SALARY_HISTORY: `${BASE_URL}/payment-history`,
    BALANCE: `${BASE_URL}/till/balance`,
    PUT_MONEY: `${BASE_URL}/till/putMoney`,
    TAKE_MONEY: `${BASE_URL}/till/takeMoney`,
    HISTORY: `${BASE_URL}/till/history`,
    RECEIVE: `${BASE_URL}/receive`,
    DETAILS: `${BASE_URL}/details`,
    SALARY_SELF: `${BASE_URL}/salary-self`
};
