import React from 'react';
import { FaTimes } from 'react-icons/fa';

const SaleReportModal = ({ report, onClose }) => {
    if (!report) return null;

    const handleClickOutside = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div
            className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50"
            onClick={handleClickOutside}
        >
            <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-1/2 p-6">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-semibold">Подробный отчёт о продаже</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <FaTimes size={24} />
                    </button>
                </div>
                <div className="mb-4">
                    <h3 className="text-lg font-semibold">Товары</h3>
                    {report.products.map(product => (
                        <div key={product.productId} className="flex justify-between">
                            <span>{product.productName} x{product.quantity}</span>
                            <span>{product.totalPrice}₽</span>
                        </div>
                    ))}
                </div>
                <div className="mb-4">
                    <h3 className="text-lg font-semibold">Итого</h3>
                    <p>{report.product_price}</p>
                </div>
                <div className="mb-4">
                    <h3 className="text-lg font-semibold">Тип оплаты</h3>
                    <p>{report.payment_type}</p>
                </div>
                <div className="mb-4">
                    <h3 className="text-lg font-semibold">Сотрудник</h3>
                    <p>{report.employee_name}</p>
                </div>
                <div className="mb-4">
                    <h3 className="text-lg font-semibold">Дата продажи</h3>
                    <p>{report.sale_time}</p>
                </div>
                <div className="mb-4">
                    <h3 className="text-lg font-semibold">Номер чека</h3>
                    <p>{report.receipt_number}</p>
                </div>
            </div>
        </div>
    );
};

export default SaleReportModal;