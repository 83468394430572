import React from 'react';
import { useDrag, useDrop } from 'react-dnd';

const COLUMN_TYPE = 'COLUMN';

const DraggableHeader = ({ id, index, moveColumn, children }) => {
    const [, ref] = useDrag({
        type: COLUMN_TYPE,
        item: { index },
    });

    const [, drop] = useDrop({
        accept: COLUMN_TYPE,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveColumn(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    return (
        <th ref={(node) => ref(drop(node))} className="py-3 px-6 text-left text-sm font-medium text-gray-500 cursor-pointer">
            {children}
        </th>
    );
};

export default DraggableHeader;