import React, { useEffect, useState } from 'react';
import InventoryTable from './InventoryTable';
import AddProductRow from './AddProductRow';
import { API_ENDPOINTS } from '../../apiConstants';
import useAuth from '../../useAuth';
import { CheckIcon, XCircleIcon, PaperAirplaneIcon } from '@heroicons/react/solid';

function InventoryPage() {
    const [products, setProducts] = useState([]);
    const { getToken } = useAuth();
    const [token, setToken] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    useEffect(() => {
        const token = getToken();
        setToken(token);
    }, [getToken]);

    const handleAddProduct = (product) => {
        const existingProduct = products.find(p => p.barcode === product.barcode);
        if (existingProduct) {
            alert('Товар с таким штрих-кодом уже добавлен!');
            return;
        }
        setProducts([...products, product]);
    };

    const handleUpdateProduct = (index, updatedProduct) => {
        const updatedProducts = products.map((product, i) =>
            i === index ? updatedProduct : product
        );
        setProducts(updatedProducts);
    };

    const handleInventoryCheck = async () => {
        if (!token) return;

        try {
            const response = await fetch(API_ENDPOINTS.PRODUCTS_INVENTORY, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ items: products })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch inventory data from API');
            }

            const { missing_in_db, missing_in_request, amount_mismatch } = await response.json();

            const updatedProducts = products.map((product) => {
                const amountIssue = amount_mismatch.find(p => p.barcode === product.barcode);
                const missingRequestIssue = missing_in_request.find(p => p.barcode === product.barcode);

                if (amountIssue) {
                    if(amountIssue.found < amountIssue.expected) {
                        return {
                            ...product,
                            issue: `Вы нашли не весь товар. Должно быть: ${amountIssue.expected}, Вы указали: ${amountIssue.found}`,
                            issueType: 'amount_mismatch'
                        };
                    } else {
                        return {
                            ...product,
                            issue: `Вы нашли БОЛЬШЕ товара, чем есть в базе данных. В базе: ${amountIssue.expected}, Вы указали: ${amountIssue.found}`,
                            issueType: 'amount_mismatch'
                        };
                    }
                }

                if (missingRequestIssue) {
                    return {
                        ...product,
                        issue: `Товар отсутствует в запросе, на складе ${missingRequestIssue.amountInStock} шт.`,
                        issueType: 'missing_in_request'
                    };
                }

                return {
                    ...product,
                    issue: null,
                    issueType: null
                };
            });

            const missingInDBProducts = missing_in_db.map((missingProduct) => ({
                name: `Неизвестный товар`,
                barcode: missingProduct.barcode,
                quantity: missingProduct.amount,
                issue: 'Товар отсутствует в базе данных',
                issueType: 'missing_in_db'
            }));

            const missingInRequestProducts = missing_in_request.map((missingProduct) => ({
                name: missingProduct.productName,
                barcode: missingProduct.barcode,
                quantity: 0,
                issue: `Товар отсутствует в запросе, на складе ${missingProduct.amountInStock} шт.`,
                issueType: 'missing_in_request'
            }));

            const finalProducts = [
                ...updatedProducts,
                ...missingInDBProducts,
                ...missingInRequestProducts
            ];

            finalProducts.sort((a, b) => (b.issue ? 1 : 0) - (a.issue ? 1 : 0));

            setProducts(finalProducts);
        } catch (error) {
            console.error('Error during inventory check:', error);
            alert('Ошибка при сверке инвентаризации.');
        }
    };

    window.addEventListener("beforeunload", (ev) =>
    {
        ev.preventDefault();
        return ev.returnValue = 'Are you sure you want to close?';
    });

    const handleSendResults = () => {
        setShowConfirmationModal(true);
    };

    const closeModal = () => {
        setShowConfirmationModal(false);
    };

    const confirmSendResults = async () => {
        setShowConfirmationModal(false);

        try {
            const response = await fetch(API_ENDPOINTS.SEND_RESULTS, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ results: products })
            });

            if (!response.ok) {
                throw new Error('Failed to send results');
            }

            alert('Результаты успешно отправлены!');
        } catch (error) {
            console.error('Error during result submission:', error);
            alert('Ошибка при отправке результатов.');
        }
    };

    return (
        <div className="min-h-screen m-4 flex flex-col">
            <h1 className="text-2xl font-semibold mb-4">Инвентаризация</h1>
            <div className="flex justify-between items-center mb-6">
                <div className="flex space-x-4 items-center">
                    <AddProductRow onAddProduct={handleAddProduct} />
                </div>
                <div className="flex space-x-4">
                    <button
                        onClick={handleInventoryCheck}
                        className="flex items-center bg-blue-600 text-white py-2 px-4 rounded-md shadow hover:bg-blue-700 transition duration-300"
                    >
                        <CheckIcon className="h-5 w-5 mr-2" /> Сверка инвентаризации
                    </button>
                    <button
                        onClick={handleSendResults}
                        className="flex items-center bg-green-600 text-white py-2 px-4 rounded-md shadow hover:bg-green-700 transition duration-300"
                    >
                        <PaperAirplaneIcon className="h-5 w-5 mr-2" /> Отправить результаты
                    </button>
                </div>
            </div>
            <div className="flex-1 overflow-auto">
                <div className="h-[calc(100vh-200px)] overflow-auto">
                    <InventoryTable
                        products={products}
                        onUpdateProduct={handleUpdateProduct}
                    />
                </div>
            </div>
            {showConfirmationModal && (
                <>
                    <div className="fixed inset-0 bg-black opacity-50 z-40"></div>
                    <div className="fixed inset-0 flex items-center justify-center z-50">
                        <div className="bg-white p-6 rounded shadow-lg relative">
                            <h2 className="text-xl font-semibold mb-4">Вы уверены, что хотите отправить результаты?</h2>
                            <div className="flex justify-end space-x-4">
                                <button
                                    onClick={closeModal}
                                    className="flex items-center bg-gray-500 text-white py-2 px-4 rounded-md hover:bg-gray-600 transition duration-300"
                                >
                                    <XCircleIcon className="h-5 w-5 mr-2" /> Отмена
                                </button>
                                <button
                                    onClick={confirmSendResults}
                                    className="flex items-center bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700 transition duration-300"
                                >
                                    <CheckIcon className="h-5 w-5 mr-2" /> Подтвердить
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default InventoryPage;
