import React, { useState } from 'react';
import DraggableHeader from './DraggableHeader';
import { FaPencilAlt } from 'react-icons/fa';
import StatusBadge from './StatusBadge';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('ru', ru);

const OrdersTable = ({ columns, orders, handleSort, getSortIcon, moveColumn }) => {
    const [editingDateId, setEditingDateId] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateClick = (orderId, date) => {
        setEditingDateId(orderId);
        setSelectedDate(new Date(date));
    };

    const handleDateChange = (date, orderId) => {
        setSelectedDate(date);
        setEditingDateId(null);
    };

    return (
        <div className="overflow-x-auto bg-white shadow-lg rounded-lg h-full flex flex-col">
            <div className="overflow-y-auto flex-grow">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50 sticky top-0 z-10">
                    <tr>
                        <th className="py-3 px-6 text-left text-sm font-medium text-gray-500 w-1 bg-gray-50"></th>
                        {columns.map((column, index) => (
                            <DraggableHeader
                                key={column.id}
                                id={column.id}
                                index={index}
                                moveColumn={moveColumn}
                            >
                                <span onClick={() => handleSort(column.id)}>
                                    {column.label}
                                    <span className="ml-2 inline-flex items-center">{getSortIcon(column.id)}</span>
                                </span>
                            </DraggableHeader>
                        ))}
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                    {orders.map((order) => (
                        <tr key={order.id} className="hover:bg-gray-100">
                            <td key={`${order.id}-edit`} className="py-4 px-6 text-sm text-gray-500">
                                <FaPencilAlt className="text-blue-500 cursor-pointer"/>
                            </td>
                            {columns.map((column) => (
                                <td key={`${order.id}-${column.id}`} className="py-4 px-6 text-sm text-gray-500 cursor-pointer">
                                    {column.id === 'deadline' ? (
                                        <div onClick={() => handleDateClick(order.id, order[column.id])}>
                                            {editingDateId === order.id ? (
                                                <DatePicker
                                                    selected={selectedDate}
                                                    onChange={(date) => handleDateChange(date, order.id)}
                                                    onClickOutside={() => setEditingDateId(null)}
                                                    withPortal
                                                    locale="ru"
                                                />
                                            ) : (
                                                order[column.id]
                                            )}
                                        </div>
                                    ) : (
                                        column.id === 'status' ? <StatusBadge status={order[column.id]} /> : order[column.id]
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OrdersTable;