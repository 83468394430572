import React from 'react';

const OrderSidebar = ({
                          isAddOrderSidebarOpen,
                          newOrder,
                          handleAddOrderChange,
                          handleAddOrder,
                          setIsAddOrderSidebarOpen
                      }) => {
    return (
        <div className={`fixed z-20 top-0 right-0 w-80 h-full bg-white border-l shadow-lg transform ${isAddOrderSidebarOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform`}>
            <div className="p-4">
                <h3 className="text-xl font-semibold mb-4">Добавить новый заказ</h3>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Заказ</label>
                    <input
                        type="text"
                        name="order"
                        placeholder="Заказ"
                        value={newOrder.order}
                        onChange={handleAddOrderChange}
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Статус</label>
                    <input
                        type="text"
                        name="status"
                        placeholder="Статус"
                        value={newOrder.status}
                        onChange={handleAddOrderChange}
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Крайний срок</label>
                    <input
                        type="date"
                        name="deadline"
                        value={newOrder.deadline}
                        onChange={handleAddOrderChange}
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Менеджер</label>
                    <input
                        type="text"
                        name="manager"
                        placeholder="Менеджер"
                        value={newOrder.manager}
                        onChange={handleAddOrderChange}
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Тип заказа</label>
                    <input
                        type="text"
                        name="orderType"
                        placeholder="Тип заказа"
                        value={newOrder.orderType}
                        onChange={handleAddOrderChange}
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Тип устройства</label>
                    <input
                        type="text"
                        name="deviceType"
                        placeholder="Тип устройства"
                        value={newOrder.deviceType}
                        onChange={handleAddOrderChange}
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Устройство</label>
                    <input
                        type="text"
                        name="device"
                        placeholder="Устройство"
                        value={newOrder.device}
                        onChange={handleAddOrderChange}
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Неисправность</label>
                    <input
                        type="text"
                        name="issue"
                        placeholder="Неисправность"
                        value={newOrder.issue}
                        onChange={handleAddOrderChange}
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Контрагент</label>
                    <input
                        type="text"
                        name="client"
                        placeholder="Контрагент"
                        value={newOrder.client}
                        onChange={handleAddOrderChange}
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">Сумма</label>
                    <input
                        type="number"
                        name="amount"
                        placeholder="Сумма"
                        value={newOrder.amount}
                        onChange={handleAddOrderChange}
                        className="border border-gray-300 p-2 rounded w-full"
                    />
                </div>
                <button
                    onClick={handleAddOrder}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                >
                    Добавить
                </button>
                <button
                    onClick={() => setIsAddOrderSidebarOpen(false)}
                    className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 ml-2"
                >
                    Закрыть
                </button>
            </div>
        </div>
    );
};

export default OrderSidebar;