import React, { useEffect, useRef } from 'react';
import { FaSearch } from 'react-icons/fa';

function SearchBar({ value, onChange, onKeyDown }) {
    const searchInputRef = useRef(null);

    useEffect(() => {
        const handleFocusCheck = (event) => {
            if (event.ctrlKey) return;

            const activeElement = document.activeElement;
            const allInputs = document.querySelectorAll('input, textarea, select');

            const isActiveElementInInputs = Array.from(allInputs).some(el => el === activeElement);

            if (!activeElement ||
                (activeElement === document.body) ||
                !isActiveElementInInputs
            ) {
                searchInputRef.current.focus();
            }
        };

        window.addEventListener('keydown', handleFocusCheck);

        return () => {
            window.removeEventListener('keydown', handleFocusCheck);
        };
    }, []);

    return (
        <div className="relative">
            <input
                type="text"
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                className="w-full p-4 border rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Начните вводить название товара, его стоимость или штрихкод"
                ref={searchInputRef}
            />
            <FaSearch className="absolute top-5 right-4 text-gray-400" />
        </div>
    );
}

export default SearchBar;