import React, { forwardRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { themeQuartz } from '@ag-grid-community/theming';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const myTheme = themeQuartz.withParams({
    browserColorScheme: "light",
    headerFontSize: 14,
});

const MyGrid = forwardRef(({ rowData, columnDefs, gridApiRef }, ref) => {
    const defaultColDef = {
        sortable: true,
        flex: 1,
        minWidth: 100,
        filter: true,
        resizable: true,
    };

    const gridOptions = {
        theme: myTheme,
        rowSelection: 'multiple',
        animateRows: true,
        domLayout: 'autoHeight',
        defaultColDef,
        columnDefs,
        rowData,
        enableCellTextSelection: true,
        suppressCellSelection: false,
        onGridReady: (params) => {
            gridApiRef.current = params.api;
            params.api.sizeColumnsToFit(); // Fit columns to grid width
        },
    };

    return (
        <div className="ag-theme-alpine" style={{ height: '400px', width: '100%' }}>
            <AgGridReact {...gridOptions} />
        </div>
    );
});

export default MyGrid;
