import React from 'react';

const statusMap = {
    in_progress: { text: 'В процессе', bgColor: 'bg-yellow-300', textColor: 'text-black' },
    completed: { text: 'Завершено', bgColor: 'bg-green-500', textColor: 'text-white' },
    cancelled: { text: 'Отменено', bgColor: 'bg-red-600', textColor: 'text-white' },
};

const StatusBadge = ({ status }) => {
    const { text, bgColor, textColor } = statusMap[status] || { text: status, bgColor: 'bg-gray-500', textColor: 'text-white' };
    return <span className={`px-2 py-1 rounded ${bgColor} ${textColor}`}>{text}</span>;
};

export default StatusBadge;