import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FaRedo, FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import { API_ENDPOINTS } from '../../apiConstants';
import useAuth from "../../useAuth";
import 'react-datepicker/dist/react-datepicker.css';

import OrdersTable from './OrdersTable';
import OrderSidebar from './OrderSidebar';
import SearchBar from './SearchBar';
import ErrorAlert from './ErrorAlert';
import LoadingSpinner from './LoadingSpinner';

const OrdersPage = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'order', direction: 'ascending' });
    const [columns, setColumns] = useState([
        { id: 'order', label: 'Заказ' },
        { id: 'status', label: 'Статус' },
        { id: 'deadline', label: 'Крайний срок' },
        { id: 'manager', label: 'Менеджер' },
        { id: 'orderType', label: 'Тип заказа' },
        { id: 'deviceType', label: 'Тип устройства' },
        { id: 'device', label: 'Устройство' },
        { id: 'issue', label: 'Неисправность' },
        { id: 'client', label: 'Контрагент' },
        { id: 'amount', label: 'Сумма' },
    ]);
    const [isAddOrderSidebarOpen, setIsAddOrderSidebarOpen] = useState(false);
    const [newOrder, setNewOrder] = useState({
        order: '',
        status: '',
        deadline: '',
        manager: '',
        orderType: '',
        deviceType: '',
        device: '',
        issue: '',
        client: '',
        amount: 0,
    });

    const { getToken } = useAuth();
    const [token, setToken] = useState(null);

    useEffect(() => {
        const token = getToken();
        setToken(token);
    }, [getToken]);

    useEffect(() => {
        if (!token) return;

        const axiosInterceptor = axios.interceptors.request.use(
            (config) => {
                config.headers.Authorization = `Bearer ${token}`;
                return config;
            },
            (error) => Promise.reject(error)
        );

        return () => {
            axios.interceptors.request.eject(axiosInterceptor);
        };
    }, [token]);

    const fetchOrders = () => {
        setLoading(true);
        setError('');
        axios.get(API_ENDPOINTS.ORDERS)
            .then(response => setOrders(response.data))
            .catch(error => {
                console.error('Ошибка при загрузке заказов:', error);
                setError('Не удалось загрузить данные о заказах. Попробуйте позже.');
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (token) {
            fetchOrders();
        }
    }, [token]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredOrders = orders.filter(
        (order) =>
            order.order.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (order.client && order.client.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedOrders = [...orders].sort((a, b) => {
            const aValue = a[key];
            const bValue = b[key];

            if (!isNaN(aValue) && !isNaN(bValue)) {
                return direction === 'ascending' ? aValue - bValue : bValue - aValue;
            }

            if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
            if (aValue > bValue) return direction === 'ascending' ? 1 : -1;
            return 0;
        });

        setOrders(sortedOrders);
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />;
        }
        return <FaSort />;
    };

    const moveColumn = useCallback((dragIndex, hoverIndex) => {
        const newColumns = [...columns];
        const [movedColumn] = newColumns.splice(dragIndex, 1);
        newColumns.splice(hoverIndex, 0, movedColumn);
        setColumns(newColumns);
    }, [columns]);

    const handleAddOrderChange = (e) => {
        const { name, value } = e.target;
        setNewOrder({
            ...newOrder,
            [name]: value
        });
    };

    const handleAddOrder = () => {
        axios.post(API_ENDPOINTS.ADD_ORDER, newOrder)
            .then(response => {
                setOrders([...orders, response.data]);
                setIsAddOrderSidebarOpen(false);
            })
            .catch(error => console.error('Ошибка при добавлении заказа:', error));
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="flex h-full">
                <OrderSidebar
                    isAddOrderSidebarOpen={isAddOrderSidebarOpen}
                    newOrder={newOrder}
                    handleAddOrderChange={handleAddOrderChange}
                    handleAddOrder={handleAddOrder}
                    setIsAddOrderSidebarOpen={setIsAddOrderSidebarOpen}
                />
                <div className="flex-grow p-6 flex flex-col">
                    <h2 className="text-2xl font-semibold mb-4">Заказы</h2>
                    <div className="flex justify-between items-center mb-4">
                        <SearchBar searchTerm={searchTerm} handleSearch={handleSearch} />
                        <div className="flex items-center">
                            <button
                                onClick={fetchOrders}
                                className="bg-gray-300 text-gray-700 p-2 rounded hover:bg-gray-400"
                            >
                                <FaRedo />
                            </button>
                            <button
                                onClick={() => setIsAddOrderSidebarOpen(true)}
                                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 ml-4 mr-4"
                            >
                                Добавить заказ
                            </button>
                        </div>
                    </div>
                    <ErrorAlert error={error} />
                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        <div className="flex-1 overflow-y-auto">
                            <OrdersTable
                                columns={columns}
                                orders={filteredOrders}
                                handleSort={handleSort}
                                getSortIcon={getSortIcon}
                                moveColumn={moveColumn}
                            />
                        </div>
                    )}
                </div>
            </div>
        </DndProvider>
    );
};

export default OrdersPage;