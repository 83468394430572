import React, { useState, useEffect, useRef } from 'react';
import { API_ENDPOINTS } from '../../apiConstants';
import useAuth from '../../useAuth';
import SearchBar from '../receiving/SearchBar';
import SuggestionsList from '../receiving/SuggestionsList';

const ReceivePage = ({ selectedStore }) => {
    const { getToken } = useAuth();
    const [token, setToken] = useState(null);
    const [products, setProducts] = useState([]);
    const [details, setDetails] = useState([]);
    const [newProduct, setNewProduct] = useState({ barcode: '', amount: 0 });
    const [newDetail, setNewDetail] = useState({ name: '', supplier: '', amount: 0 });
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const quantityInputRef = useRef(null); // Create a reference for the quantity input field
    const inputRef = useRef(null);

    useEffect(() => {
        const token = getToken();
        setToken(token);
    }, [getToken]);

    const validateProduct = (product) => {
        return product.barcode.trim() !== '' && product.amount > 0;
    };

    const fixProduct = () => {
        //do virtual click to suggestion
        if (suggestions.length > 0) {
            handleSelectSuggestion(suggestions);
            //setSuggestions([]);
        }

        //fix amount to be integer and not string
        setNewProduct({ ...newProduct, amount: parseInt(newProduct.amount, 10) });

        // set barcode from search term if it's not set
        // fetch barcode from API in that case
        if (newProduct.barcode.trim() === '') {
            // set barcode to be the first suggestion's barcode
            if (suggestions.length > 0) {
                setNewProduct({ ...newProduct, barcode: suggestions[0].barcode, name: suggestions[0].productName });
            }
        }
    }

    const validateDetail = (detail) => {
        return detail.name.trim() !== '' && detail.supplier.trim() !== '' && detail.amount > 0;
    };

    const addProduct = () => {
        fixProduct();
        if (validateProduct(newProduct)) {
            //check if product already added
            const existingProduct = products.find(product => product.barcode === newProduct.barcode);
            if (existingProduct) {
                const updatedProduct = { ...existingProduct, amount: existingProduct.amount + parseInt(newProduct.amount, 10) };
                setProducts(products.map(product => product.barcode === newProduct.barcode ? updatedProduct : product));
            }else {
                setProducts([{
                    barcode: newProduct.barcode,
                    name: newProduct.name,
                    amount: parseInt(newProduct.amount, 10)
                }, ...products]);
            }
            setNewProduct({ barcode: '', name: '', amount: 0 });
            setSearchTerm('');
            setSuggestions([]);
            inputRef.current.focus(); // Focus the search input field
        }
    };

    const addDetail = () => {
        if (validateDetail(newDetail)) {
            setDetails([newDetail, ...details]);
            setNewDetail({ name: '', supplier: '', amount: 0 });
        }
    };

    const handleProductKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            quantityInputRef.current.focus(); // Focus the quantity input field
            //add product
            addProduct();
        }
    };

    const handleDetailKeyDown = (e) => {
        if (e.key === 'Enter') {
            addDetail();
        }
    };

    const handleSubmit = async () => {
        if (!token) return;

        if (products.length === 0) {
            alert('Пожалуйста добавьте хотя бы один товар');
            return;
        }

        const productData = products.reduce((acc, product) => {
            if (product.barcode) acc[product.barcode] = parseInt(product.amount, 10);
            return acc;
        }, {});

        const detailData = details.map(detail => ({
            name: detail.name,
            supplier: detail.supplier,
            amount: parseInt(detail.amount, 10),
        }));

        const data = {
            shop_id: selectedStore,
            products: productData,
            details: detailData,
        };

        try {
            const response = await fetch(API_ENDPOINTS.RECEIVE, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            });
            const result = await response.json();

            if (response.ok) {
                console.log('Приёмка успешно выполнена:', result);
                setProducts([]);
                setDetails([]);
            } else {
                console.error('Ошибка при приёмке:', result);
            }
        } catch (error) {
            console.error('Ошибка при приёмке:', error);
        }
    };

    const fetchSuggestions = async (term) => {
        setLoading(true);
        try {
            const response = await fetch(`${API_ENDPOINTS.PRODUCTS}?search=${term}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            const result = await response.json();
            if (Array.isArray(result)) {
                setSuggestions(result);
                console.log(result);
            } else {
                //setSuggestions([]);
            }
        } catch (error) {
            console.error('Ошибка при загрузке предложений:', error);
            setSuggestions([]);
        } finally {
            setLoading(false);
        }
    };

    const debounceTimeout = useRef(null);

    const handleSearchChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            if (term.trim() !== '') {
                fetchSuggestions(term);
            } else {
                setSuggestions([]);
            }
        }, 500);
    };

    const handleSelectSuggestion = (selected) => {
        if (selected.length > 0) {
            setNewProduct({ barcode: selected[0].barcode, name: selected[0].productName });
            setSearchTerm(selected[0].productName);
            setSuggestions([]);
        }
    };

    const removeProduct = (index) => {
        setProducts(products.filter((_, i) => i !== index));
    };

    return (
        <div className="min-h-screen bg-gray-50 p-8">
            <div className="max-w-4xl mx-auto bg-white p-6 shadow-md rounded-lg">
                <h1 className="text-2xl font-semibold text-gray-700 mb-6">Приёмка товаров и запчастей</h1>

                {/* Товары */}
                <div className="mb-6">
                    <h2 className="text-lg font-semibold text-gray-600 mb-4">Товары</h2>

                    <div className="flex space-x-4 mb-4">
                        <div className="w-1/2">
                            <SearchBar
                                value={searchTerm}
                                onChange={handleSearchChange}
                                onKeyDown={handleProductKeyDown}
                                ref={inputRef}
                            />
                            <SuggestionsList
                                suggestions={suggestions.length > 1 ? suggestions : []} // Изменение здесь
                                onSelect={handleSelectSuggestion}
                                searchTerm={searchTerm}
                                loading={loading}
                            />

                        </div>
                        <input
                            type="number"
                            value={newProduct.amount}
                            onChange={(e) => setNewProduct({...newProduct, amount: e.target.value})}
                            onKeyDown={handleProductKeyDown}
                            placeholder="Количество"
                            className="w-1/2 p-2 border border-gray-300 rounded"
                            ref={quantityInputRef} // Attach the reference to the quantity input field
                        />
                        <button
                            onClick={addProduct}
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
                        >
                            Добавить товар
                        </button>
                    </div>

                    <table className="min-w-full mt-4 bg-gray-100 rounded">
                        <thead>
                        <tr className="bg-blue-500 text-white">
                            <th className="p-2 text-left">Название</th>
                            <th className="p-2 text-left">Артикул</th>
                            <th className="p-2 text-left">Количество</th>
                            <th className="p-2 text-left">Действие</th>
                            {/* Добавляем столбец для удаления */}
                        </tr>
                        </thead>
                        <tbody>
                        {products.map((product, index) => (
                            <tr key={index} className="border-b border-gray-200">
                                <td className="p-2">{product.name}</td>
                                <td className="p-2">{product.barcode}</td>
                                <td className="p-2">{product.amount}</td>
                                <td className="p-2">
                                    <button
                                        onClick={() => removeProduct(index)}
                                        className="text-red-500 hover:text-red-700"
                                    >
                                        Удалить
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                {/* Детали */}
                <div className="mb-6">
                    <h2 className="text-lg font-semibold text-gray-600 mb-4">Детали</h2>

                    <div className="flex space-x-4 mb-4">
                        <input
                            type="text"
                            value={newDetail.name}
                            onChange={(e) => setNewDetail({...newDetail, name: e.target.value})}
                            onKeyDown={handleDetailKeyDown}
                            placeholder="Название"
                            className="w-1/3 p-2 border border-gray-300 rounded"
                        />
                        <input
                            type="text"
                            value={newDetail.supplier}
                            onChange={(e) => setNewDetail({...newDetail, supplier: e.target.value})}
                            onKeyDown={handleDetailKeyDown}
                            placeholder="Поставщик"
                            className="w-1/3 p-2 border border-gray-300 rounded"
                        />
                        <input
                            type="number"
                            value={newDetail.amount}
                            onChange={(e) => setNewDetail({ ...newDetail, amount: e.target.value })}
                            onKeyDown={handleDetailKeyDown}
                            placeholder="Количество"
                            className="w-1/3 p-2 border border-gray-300 rounded"
                        />
                        <button
                            onClick={addDetail}
                            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
                        >
                            Добавить деталь
                        </button>
                    </div>

                    <table className="min-w-full mt-4 bg-gray-100 rounded">
                        <thead>
                        <tr className="bg-green-500 text-white">
                            <th className="p-2 text-left">Название</th>
                            <th className="p-2 text-left">Поставщик</th>
                            <th className="p-2 text-left">Количество</th>
                        </tr>
                        </thead>
                        <tbody>
                        {details.map((detail, index) => (
                            <tr key={index} className="border-b border-gray-200">
                                <td className="p-2">{detail.name}</td>
                                <td className="p-2">{detail.supplier}</td>
                                <td className="p-2">{detail.amount}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <button
                    onClick={handleSubmit}
                    className="w-full px-4 py-3 bg-indigo-500 text-white font-semibold rounded hover:bg-indigo-600 transition"
                >
                    Отправить приёмку
                </button>
            </div>
        </div>
    );
};

export default ReceivePage;