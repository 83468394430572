import React from 'react';
import { FaTrash, FaPlus, FaMinus } from 'react-icons/fa';

function Cart({ items, onRemove, onIncrease, onDecrease, onQuantityChange }) {
    return (
        <div className="flex-grow">
            <h2 className="text-xl font-semibold mb-4">Корзина</h2>
            {items.length === 0 ? (
                <p className="text-gray-500">Не выбран ни один товар.</p>
            ) : (
                items.map((item) => (
                    <div key={item.id} className="flex justify-between items-center p-2 border-b">
                        <div>
                            <p className="font-medium">{item.productName}</p>
                            <div className="flex items-center">
                                <button
                                    onClick={() => onDecrease(item)}
                                    className="p-1 text-gray-500 hover:text-gray-700"
                                >
                                    <FaMinus />
                                </button>
                                <input
                                    type="number"
                                    value={item.quantity}
                                    onChange={(e) => onQuantityChange(item, e.target.value)}
                                    className="mx-2 w-16 p-1 border border-gray-300 rounded"
                                />
                                <button
                                    onClick={() => onIncrease(item)}
                                    className="p-1 text-gray-500 hover:text-gray-700"
                                >
                                    <FaPlus />
                                </button>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <p className="mr-4">{(item.retailPrice * item.quantity).toFixed(2)}₽</p>
                            <FaTrash
                                className="text-red-500 cursor-pointer"
                                onClick={() => onRemove(item)}
                            />
                        </div>
                    </div>
                ))
            )}
        </div>
    );
}

export default Cart;