import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    FaHome,
    FaBox,
    FaFileAlt,
    FaSignOutAlt,
    FaStore,
    FaChevronDown,
    FaClipboardList,
    FaToolbox, FaReceipt, FaCashRegister, FaMicrochip
} from 'react-icons/fa';
import {FaMoneyBill1} from "react-icons/fa6";

const storeOptions = [
    { name: 'Звенигородская', id: 1 },
    { name: 'Гирлянды', id: 2 },
    { name: 'Магазин', id: 3 }
];

const Sidebar = ({ width, onLogout, setSelectedStore }) => {
    const [selectedStore, setSelectedStoreState] = useState(storeOptions[2].id);
    const location = useLocation();

    const handleStoreChange = (event) => {
        const newStoreId = parseInt(event.target.value, 10);
        setSelectedStoreState(newStoreId);
        setSelectedStore(newStoreId);
    };

    return (
        <div
            className="bg-gray-800 text-white fixed top-0 left-0 h-full flex flex-col"
            style={{ width: `${width}px` }}
        >
            <div className="p-4 bg-gray-800 flex items-center">
                <img
                    src="https://static.tildacdn.com/tild3338-6463-4662-b330-663562636336/Artboard__2.png"
                    alt="Logo"
                    className="h-10 mr-2"
                />
                <h1 className="text-lg font-bold">Фри Сервис</h1>
            </div>
            <ul className="flex-1 mt-4 space-y-2 ml-1">
                <li>
                    <Link
                        to="/"
                        className={`flex items-center py-2 px-4 rounded ${location.pathname === '/' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
                    >
                        <FaHome className="mr-3"/>
                        Главная
                    </Link>
                </li>
                <hr className="w-48 h-0.5 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
                <li>
                    <Link
                        to="/products"
                        className={`flex items-center py-2 px-4 rounded ${location.pathname === '/products' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
                    >
                        <FaBox className="mr-3"/>
                        Товары
                    </Link>
                </li>
                <li>
                    <Link
                        to="/details"
                        className={`flex items-center py-2 px-4 rounded ${location.pathname === '/details' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
                    >
                        <FaMicrochip className="mr-3"/>
                        Детали
                    </Link>
                </li>
                <li>
                    <Link
                        to="/orders"
                        className={`flex items-center py-2 px-4 rounded ${location.pathname === '/orders' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
                    >
                        <FaToolbox className="mr-3"/>
                        Заказы
                    </Link>
                </li>
                <hr className="w-48 h-0.5 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
                <li>
                    <Link
                        to="/sales"
                        className={`flex items-center py-2 px-4 rounded ${location.pathname === '/sales' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
                    >
                        <FaStore className="mr-3"/>
                        Продажа
                    </Link>
                </li>
                <li>
                    <Link
                        to="/till"
                        className={`flex items-center py-2 px-4 rounded ${location.pathname === '/till' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
                    >
                        <FaCashRegister className="mr-3"/>
                        Касса
                    </Link>
                </li>
                <li>
                    <Link
                        to="/reports"
                        className={`flex items-center py-2 px-4 rounded ${location.pathname === '/reports' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
                    >
                        <FaFileAlt className="mr-3"/>
                        Отчёты
                    </Link>
                </li>
                <hr className="w-48 h-0.5 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
                <li>
                    <Link
                        to="/receiving"
                        className={`flex items-center py-2 px-4 rounded ${location.pathname === '/receiving' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
                    >
                        <FaReceipt className="mr-3"/>
                        Приёмка товара
                    </Link>
                </li>
                <li>
                    <Link
                        to="/inventory"
                        className={`flex items-center py-2 px-4 rounded ${location.pathname === '/inventory' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
                    >
                        <FaClipboardList className="mr-3"/>
                        Инвентаризация
                    </Link>
                </li>
                {/*<li>*/}
                {/*    <Link*/}
                {/*        to="/inventory/init"*/}
                {/*        className={`flex items-center py-2 px-4 rounded ${location.pathname === '/inventory/init' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}*/}
                {/*    >*/}
                {/*        <FaClipboardCheck className="mr-3"/>*/}
                {/*        Первичная инвентаризация*/}
                {/*    </Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*    <Link*/}
                {/*        to="/clients"*/}
                {/*        className={`flex items-center py-2 px-4 rounded ${location.pathname === '/clients' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}*/}
                {/*    >*/}
                {/*        <FaUsers className="mr-3"/>*/}
                {/*        Сотрудники*/}
                {/*    </Link>*/}
                {/*</li>*/}
                <hr className="w-48 h-0.5 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700"></hr>
                <li>
                    <Link
                        to="/salary"
                        className={`flex items-center py-2 px-4 rounded ${location.pathname === '/salary' ? 'bg-gray-700' : 'hover:bg-gray-700'}`}
                    >
                        <FaMoneyBill1 className="mr-3"/>
                        Зарплата
                    </Link>
                </li>
            </ul>

            <div className="px-4 py-2 mb-4">
                <label className="text-sm font-medium text-gray-300 block mb-2">Предприятие</label>
                <div className="relative">
                    <FaStore className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400"/>
                    <select
                        value={selectedStore}
                        onChange={handleStoreChange}
                        className="appearance-none w-full bg-gray-700 text-white py-2 pl-8 pr-10 rounded leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                        style={{ paddingRight: '2.5rem' }}
                    >
                        {storeOptions.map(store => (
                            <option key={store.id} value={store.id}>{store.name}</option>
                        ))}
                    </select>
                    <FaChevronDown className="pointer-events-none absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                </div>
            </div>

            <button
                onClick={onLogout}
                className="flex items-center py-2 px-4 hover:bg-gray-700 rounded mt-auto mb-4 ml-1"
            >
                <FaSignOutAlt className="mr-3" />
                Выйти
            </button>
        </div>
    );
};

export default Sidebar;