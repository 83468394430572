import React, { useState, useCallback, useEffect } from 'react';
import { HashRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Content from './components/Content';
import LoginPage from './components/LoginPage';
import ProductsPage from './components/products/ProductsPage';
import ReportsPage from './components/reports/ReportsPage';
import ClientsPage from './components/ClientsPage';
import useAuth from './useAuth';
import SalesPage from "./components/sales/SalesPage";
import InventoryPage from "./components/inventory/InventoryPage";
import OrdersPage from "./components/orders/OrdersPage";
import InitialInventoryPage from "./components/initinventory/InitialInventoryPage";
import ReceivingPage from "./components/receiving/ReceivingPage";
import SalaryPage from "./components/salary/SalaryPage";
import TillComponent from "./components/till/TillComponent";
import DetailsTable from "./components/details/DetailsTable";

function MainLayout({ sidebarWidth, isAuthenticated, children }) {
    const location = useLocation();
    const [marginLeft, setMarginLeft] = useState('0px');

    useEffect(() => {
        if (isAuthenticated && location.pathname !== '/login') {
            setMarginLeft(`${sidebarWidth}px`);
        } else {
            setMarginLeft('0px');
        }
    }, [isAuthenticated, location.pathname, sidebarWidth]);

    return (
        <div
            className="flex-1"
            style={{
                marginLeft: marginLeft,
                transition: 'margin-left 0s ease'
            }}
        >
            {children}
        </div>
    );
}

function App() {
    const [sidebarWidth, setSidebarWidth] = useState(225);
    const { isAuthenticated, login, logout } = useAuth();
    const [selectedStore, setSelectedStore] = useState(3);

    const handleMouseDown = useCallback((e) => {
        e.preventDefault();
        const startX = e.clientX;

        const onMouseMove = (moveEvent) => {
            const newWidth = sidebarWidth + (moveEvent.clientX - startX);
            if (newWidth >= 225 && newWidth < 500) {
                setSidebarWidth(newWidth);
            }
        };

        const onMouseUp = () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };

        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
    }, [sidebarWidth]);

    return (
        <Router>
            <div className="flex h-screen bg-gray-100">
                {isAuthenticated && (
                    <>
                        <Sidebar width={sidebarWidth} onLogout={logout} setSelectedStore={setSelectedStore} />
                        <div
                            className="w-1 bg-gray-300 cursor-col-resize"
                            onMouseDown={handleMouseDown}
                        />
                    </>
                )}
                <MainLayout sidebarWidth={sidebarWidth} isAuthenticated={isAuthenticated}>
                    <Routes>
                        <Route
                            path="/login"
                            element={!isAuthenticated ? <LoginPage login={login} /> : <Navigate to="/" replace />}
                        />
                        <Route
                            path="/"
                            element={isAuthenticated ? <Content /> : <Navigate to="/login" replace />}
                        />
                        <Route
                            path="/products"
                            element={isAuthenticated ? <ProductsPage selectedStore={selectedStore} /> : <Navigate to="/login" replace />}
                        />
                        <Route
                            path="/sales"
                            element={isAuthenticated ? <SalesPage selectedStore={selectedStore} /> : <Navigate to="/login" replace />}
                        />
                        <Route
                            path="/orders"
                            element={isAuthenticated ? <OrdersPage /> : <Navigate to="/login" replace />}
                        />
                        <Route
                            path="/reports"
                            element={isAuthenticated ? <ReportsPage selectedStore={selectedStore} /> : <Navigate to="/login" replace />}
                        />
                        <Route
                            path="/clients"
                            element={isAuthenticated ? <ClientsPage /> : <Navigate to="/login" replace />}
                        />
                        <Route
                            path="/inventory"
                            element={isAuthenticated ? <InventoryPage /> : <Navigate to="/login" replace />}
                        />
                        <Route
                            path="/inventory/init"
                            element={isAuthenticated ? <InitialInventoryPage selectedStore={selectedStore} /> : <Navigate to="/login" replace />}
                        />
                        <Route
                            path="/receiving"
                            element={isAuthenticated ? <ReceivingPage selectedStore={selectedStore} /> : <Navigate to="/login" replace />}
                        />
                        <Route
                            path="/salary"
                            element={isAuthenticated ? <SalaryPage selectedStore={selectedStore} /> : <Navigate to="/login" replace />}
                        />
                        <Route
                            path="/till"
                            element={isAuthenticated ? <TillComponent selectedStore={selectedStore} /> : <Navigate to="/login" replace />}
                        />
                        <Route
                            path="/details"
                            element={isAuthenticated ? <DetailsTable selectedStore={selectedStore} /> : <Navigate to="/login" replace />}
                        />
                    </Routes>
                </MainLayout>
            </div>
        </Router>
    );
}

export default App;