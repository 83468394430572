import React, {useState, useEffect, useCallback, useRef} from 'react';
import axios from 'axios';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {FaPrint, FaRedo} from 'react-icons/fa';
import {API_ENDPOINTS} from '../../apiConstants';
import useAuth from "../../useAuth";

import ProductSidebar from './ProductSidebar';
import SearchBar from './SearchBar';
import ErrorAlert from './ErrorAlert';
import LoadingSpinner from './LoadingSpinner';
import {Link} from "react-router-dom";
import MyGrid from '../products/MyGrid';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import JsBarcode from "jsbarcode";

const ProductsPage = ({selectedStore}) => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [isAddProductSidebarOpen, setIsAddProductSidebarOpen] = useState(false);
    const [newProduct, setNewProduct] = useState({
        productName: '',
        retailPrice: 0,
        stockPrice: 0,
        salary: 0,
        amountInStock: 0,
        author: 1,
        category: null,
        brand: null,
        barcode: '',
        allowedForSale: 1
    });

    const {getToken} = useAuth();
    const [token, setToken] = useState(null);


    useEffect(() => {
        const token = getToken();
        setToken(token);
    }, [getToken]);

    useEffect(() => {
        if (!token) return;

        const axiosInterceptor = axios.interceptors.request.use(
            (config) => {
                config.headers.Authorization = `Bearer ${token}`;
                return config;
            },
            (error) => Promise.reject(error)
        );

        return () => {
            axios.interceptors.request.eject(axiosInterceptor);
        };
    }, [token]);

    const fetchProducts = useCallback(() => {
        setLoading(true);
        setError('');
        axios.get(API_ENDPOINTS.PRODUCTS, {
            params: {
                shop_id: selectedStore
            }
        })
            .then(response => setProducts(response.data))
            .catch(error => {
                console.error('Ошибка при загрузке товаров:', error);
                setError('Не удалось загрузить данные о товарах. Попробуйте позже.');
            })
            .finally(() => setLoading(false));
    }, [selectedStore]);

    useEffect(() => {
        if (token) {
            fetchProducts();
        }
    }, [token, fetchProducts]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredProducts = products.filter(
        (product) =>
            product.productName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            (product.barcode && product.barcode.includes(searchTerm))
    );

    const handleAddProductChange = (e) => {
        const {name, value} = e.target;
        setNewProduct({
            ...newProduct,
            [name]: value
        });
    };

    const handleAddProduct = () => {
        // with bearer token via API_ENDPOINTS.ADD_PRODUCT
        /**
         * 'productName'
         * 'retailPrice'
         * 'stockPrice'
         * 'amountInStock'
         * 'allowedForSale'
         * 'barcode'
         */
        axios.post(API_ENDPOINTS.ADD_PRODUCT, {
            shop_id: selectedStore,
            productName: newProduct.productName,
            retailPrice: newProduct.retailPrice,
            stockPrice: newProduct.stockPrice,
            amountInStock: newProduct.amountInStock,
            allowedForSale: newProduct.allowedForSale,
            barcode: newProduct.barcode,
            salary: newProduct.salary,
        }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then(() => {
                fetchProducts();});
    };

    const columnDefs = [
        {
            headerCheckboxSelection: true,
            checkboxSelection: true,
            headerName: "Название",
            field: "productName",
            filter: 'agTextColumnFilter',
            sortable: true
        },
        {headerName: "Цена", field: "retailPrice", filter: 'agNumberColumnFilter', sortable: true},
        {headerName: "Товарный остаток", field: "amountInStock", filter: 'agNumberColumnFilter', sortable: true},
        {headerName: "Штрихкод", field: "barcode", filter: 'agTextColumnFilter', sortable: true},
        {headerName: "Бренд", field: "brand", filter: 'agTextColumnFilter', sortable: true},
        {headerName: "Категория", field: "category", filter: 'agTextColumnFilter', sortable: true}
    ];

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const generateBarcode = (text) => {
        return new Promise((resolve) => {
            const canvas = document.createElement("canvas");

            JsBarcode(canvas, text, {
                format: "CODE128",
                width: 1,
                height: 25,
                displayValue: false,
                font: "Roboto",
                fontSize: 20,
            });

            const base64 = canvas.toDataURL("image/png");
            resolve(base64);
        });
    };



    const gridApiRef = useRef(null);

    async function printProducts() {

        const mm = 2.835;

        const content = [];

        let selectedRows = gridApiRef.current.getSelectedRows();

        if (selectedRows.length === 0) {
            selectedRows = products;
        }

        for (const product of selectedRows) {
            const barcodeImage = await generateBarcode(product.barcode);

            let floatValue = parseFloat(product.retailPrice);

            let price = Math.floor(floatValue);

            content.push(
                {
                    image: barcodeImage,
                    width: 60,
                    absolutePosition: {x: mm*23, y: mm*11},
                },
                {
                    text: price + " ₽",
                    absolutePosition: {x: mm*1, y: mm*13},
                    fontSize: 18,
                    bold: true
                },
                {
                    text: product.barcode,
                    absolutePosition: {x: mm*33.5, y: mm*19.5},
                    fontSize: 7,
                },
                {
                    text: "арт. " + product.viewer_id,
                    absolutePosition: {x: mm*29, y: mm*10.7},
                    fontSize: 6,
                },
                {
                    text: "Китай",
                    absolutePosition: {x: mm*26, y: mm*20},
                    fontSize: 5
                },
                {
                    text: product.productName,
                    absolutePosition: {x: 0, y: 5},
                    fontSize: 7,
                    alignment: 'center'
                },
            );
            if (product !== selectedRows[selectedRows.length - 1]) {
                content.push({text: '', pageBreak: 'after'});
            }
        }

        let w = mm * 43;
        let h = mm * 25;
        const docDefinition = {
            pageOrientation: 'landscape',
            pageSize: {width: w, height: h},
            pageMargins: [0, 0, 0, 0],
            defaultStyle: {
                font: 'Roboto',
                fontSize: 10
            },
            content: content
        };

        pdfMake.createPdf(docDefinition).open();
    }


    return (
        <DndProvider backend={HTML5Backend}>
            <div className="flex h-full">
                <ProductSidebar
                    isAddProductSidebarOpen={isAddProductSidebarOpen}
                    newProduct={newProduct}
                    handleAddProductChange={handleAddProductChange}
                    handleAddProduct={handleAddProduct}
                    setIsAddProductSidebarOpen={setIsAddProductSidebarOpen}
                />
                <div className="flex-grow p-6 flex flex-col">
                    <h2 className="text-2xl font-semibold mb-4">Товары</h2>
                    <div className="flex justify-between items-center mb-4">
                        <SearchBar searchTerm={searchTerm} handleSearch={handleSearch}/>
                        <div className="flex items-center">
                            <button
                                onClick={fetchProducts}
                                className="bg-gray-300 text-gray-700 p-2 rounded hover:bg-gray-400"
                            >
                                <FaRedo/>
                            </button>
                            <button
                                onClick={printProducts}
                                className="bg-gray-300 ml-2 text-gray-700 p-2 rounded hover:bg-gray-400"
                            >
                                <FaPrint/>
                            </button>
                            <button
                                onClick={() => setIsAddProductSidebarOpen(true)}
                                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 ml-4 mr-4"
                            >
                                Добавить товар
                            </button>
                            <Link to="/inventory">
                                <button
                                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                                >
                                    Начать инвентаризацию
                                </button>
                            </Link>
                        </div>
                    </div>
                    <ErrorAlert error={error}/>
                    {loading ? (
                        <LoadingSpinner/>
                    ) : (
                            <MyGrid
                                rowData={filteredProducts}
                                columnDefs={columnDefs}
                                gridApiRef={gridApiRef}
                                defaultColDef={{
                                    sortable: true,
                                    flex: 1,
                                    minWidth: 100,
                                    filter: true,
                                    resizable: true
                                }}
                                gridOptions={{
                                    rowSelection: 'multiple',
                                    animateRows: true,
                                    domLayout: 'autoHeight',
                                    enableRangeSelection: true,
                                    enableCharts: true,
                                    enableStatusBar: true,
                                    enableSideBar: true,
                                    alwaysShowVerticalScroll: true
                                }}
                            />
                    )}
                </div>
            </div>
        </DndProvider>
    );
};

export default ProductsPage;